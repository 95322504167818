import { signOut } from 'firebase/auth';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../constext/AuthProvider/Authprovider';

const Header = () => {
    const { logOut } = useContext(AuthContext)

    const signOut = (event) => {
        logOut()
            .then(() => {
                // Sign-out successful.
                alert('signOut successfully')
            }).catch((error) => {
                // An error happened.
                console.log(error)
            });

    }

    const productItems = <>
        <li><Link to='/products'>Jute</Link></li>
        <li><Link to='/bedding'>Bedding</Link></li>
        <li><Link to='/seagrass'>Seagrass</Link></li>
    </>

    const abouts = <>
        <li><Link to='/about'>About Coco Villagebd</Link></li>
        <li><Link to='/topteam'>Top Team</Link></li>
    </>
    const concerns = <>
        <li><Link to=''>Coco Village BD </Link></li>
        <li><Link to=''>One Hometex </Link></li>
        <li><Link to=''>Coco Village Fabric </Link></li>
        <li><Link to=''>Coco Village Lather </Link></li>
        <li><Link to=''>It & Design Studio </Link></li>
        <li><Link to=''>Accord Foundation  </Link></li>
    </>

    return (

        <div className='fixed w-full z-20 top-0 left-0'>
            <h2 className='text-center text-xs  bg-base-100 '><a href="tel:+8801610751185">Hot Line: +8801610751185</a> </h2>
            <div className="navbar bg-base-100 ">
                <h3></h3>
                <div className="navbar-start">
                    <div className="dropdown">
                        <label tabIndex={0} className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>
                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                            <li><Link to="/">Home</Link></li>

                            <li tabIndex={0}>
                                <Link to=''>
                                    Products
                                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                </Link>
                                <ul className="p-2 bg-warning">
                                    {productItems}
                                </ul>
                            </li>
                            <li tabIndex={2}>
                                <Link to=''>
                                    Concerns
                                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                </Link>
                                <ul className="p-2 bg-secondary">
                                    {concerns}
                                </ul>
                            </li>


                            <li tabIndex={1}>
                                <Link to=''>
                                    About Us
                                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                </Link>
                                <ul className="p-2 bg-secondary">
                                    {abouts}
                                </ul>
                            </li>
                            <li><Link to="contact">Contact Us</Link></li>
                        </ul>
                    </div>
                    <Link to="/" className="btn btn-ghost normal-case text-3xl font-bold">CocoVillegeBD</Link>
                </div>
                <div className="navbar-center hidden lg:flex">
                    <ul className="menu menu-horizontal px-1">
                        <li><Link to="/">Home</Link></li>
                        <li tabIndex={0}>
                            <Link to='/products'>
                                Products
                                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                            </Link>
                            <ul className="p-2 bg-danger">
                                {productItems}
                            </ul>
                        </li>
                        <li tabIndex={2}>
                            <Link to=''>
                                Concerns
                                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                            </Link>
                            <ul className="p-2 bg-secondary">
                                {concerns}
                            </ul>
                        </li>
                        <li tabIndex={1}>
                            <Link to=''>
                                About Us
                                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                            </Link>
                            <ul className="p-2 bg-secondary">
                                {abouts}
                            </ul>
                        </li>
                        <li><Link to="/contact">Contact Us</Link></li>
                    </ul>
                </div>
                <div className="navbar-end">
                    <Link className="btn" to="/login">log In</Link>
                </div>
            </div>
        </div>
    );
};

export default Header; <h3>this is header</h3>